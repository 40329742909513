






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";
import warehouseExportStatuses from "@/constants/warehouseExportStatuses";
import makeSelectOptions from "@common/util/makeSelectOptions";

export default Vue.extend({
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "statusValue",
          "sort-desc": false,
          "show-select": true,
          "items-per-page": 10,
        },
        content: {
          name: "Quản lý xuất kho",
          urlEnabled: true,
          search: {},
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
            },
            createdByUser: {
              text: "Người gửi đơn",
              options: {
                subProp: "createdByUser.name",
                sortBy: "createdByUserId",
              },
            },
            lastUpdatedByUser: {
              text: "Người update tracking number",
              options: {
                subProp: "lastUpdatedByUser.name",
                sortBy: "lastUpdatedByUserId",
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            xkTime: {
              text: "Ngày xuất kho",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            srcWarehouseExportId: {
              text: "ID nguồn",
              sortable: true,
            },
            warehouse: {
              text: "Kho",
              sortable: true,
              options: {
                subProp: "warehouse.name",
              },
            },
            shippingCompanies: {
              text: "ĐVVC",
              sortable: true,
              options: {
                labels(_, item) {
                  const shippingCompanyNames = [];
                  for (const order of item.orders) {
                    if (order.shippingCompany && !shippingCompanyNames.includes(order.shippingCompany.name)) {
                      shippingCompanyNames.push(order.shippingCompany.name);
                    }
                  }
                  return shippingCompanyNames;
                },
              },
            },
            shippingCompanyAccounts: {
              text: "TK ĐVVC",
              sortable: true,
              options: {
                labels(_, item) {
                  const shippingCompanyAccountNames = [];
                  for (const order of item.orders) {
                    if (
                      order.shippingCompanyAccount &&
                      !shippingCompanyAccountNames.includes(order.shippingCompanyAccount.name)
                    ) {
                      shippingCompanyAccountNames.push(order.shippingCompanyAccount.name);
                    }
                  }
                  return shippingCompanyAccountNames;
                },
              },
            },
            status: {
              text: "Trạng thái xuất kho",
              sortable: true,
              options: {
                label(value) {
                  return warehouseExportStatuses[value || ""];
                },
                attrs(value) {
                  const data: any = {};
                  switch (value) {
                    case "kt":
                      data.color = "indigo";
                      break;
                    case "cxk":
                      data.color = "#ffe599";
                      data.dark = false;
                      break;
                    case "dxk":
                      data.color = "#a4d78e";
                      data.dark = false;
                      break;
                  }
                  return data;
                },
              },
            },
            orders: {
              text: "Orders",
              sortable: true,
              options: {
                labels: true,
                labelItems(value) {
                  return value.orderId;
                },
              },
            },
            description: {
              text: "Mô tả",
            },
            orderCount: {
              text: "Số lượng",
              options: {
                transform(_, item) {
                  return item.orders && item.orders.length;
                },
              },
            },
            jntSent: {
              text: "Đã gửi J&T",
              options: {
                boolean: true,
              },
            },
            action: {},
          },
          filters: {
            filters: {
              _id: {
                attrs: {
                  label: "ID",
                },
                rules: {
                  equal_to_id: {},
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái xuất kho",
                  multiple: true,
                  items: makeSelectOptions(warehouseExportStatuses),
                },
                rules: {
                  in: {},
                },
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              createdByUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Người tạo",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              lastUpdatedByUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Người xuất kho",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              orderIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Order ID",
                  required: true,
                  "item-value": "_id",
                  "item-text": "orderId",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("orders", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            filters: [
                              {
                                key: "orderId",
                                operator: "equal_to",
                                value: search,
                              },
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                    },
                  },
                },
              },
              xkTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày xuất kho (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày xuất kho (kết thúc)",
                    },
                  },
                },
              },
              updatedTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày update tracking number (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày update tracking number (kết thúc)",
                    },
                  },
                },
              },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiWorkerClient.download("warehouseExports", `@/export`, findAllOptions);
                },
              },
            },
            importTransport: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Cập nhật trạng thái pickup",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Cập nhật trạng thái pick up" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Cập nhật trạng thái pick up",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                  importType: {
                                    type: "select",
                                    attrs: {
                                      items: [
                                        { value: "detach", text: "Tách đơn" },
                                        {
                                          value: "updateTrackingNumber",
                                          text: "Cập nhật tracking number",
                                        },
                                      ],
                                    },
                                    ext: {
                                      defaultValue: "detach",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiWorkerClient.call(
                                  "warehouseExports",
                                  "importTransportAll",
                                  {
                                    importType: data.importType,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              const lines = [];
                              lines.push(`Số lượng đơn hàng được cập nhật thành công: ${result.doneCount}`);
                              lines.push(
                                `Số lượng đơn hàng không được cập nhật thành công: ${
                                  result.notDoneOrderIds && result.notDoneOrderIds.length
                                }`
                              );
                              lines.push(
                                `Các đơn hàng không được cập nhật thành công: ${
                                  result.notDoneOrderIds && result.notDoneOrderIds.join(",")
                                }`
                              );
                              form.success.value = lines.join("<br/>");
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: (item) => item.description || item._id,
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              exportTransport: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                content: {
                  icon: "mdi-download",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    coreApiWorkerClient.download("warehouseExports", `${item._id}/exportTransport`);
                  },
                },
                target: {
                  tooltip: { content: { text: "Xuất file cho ĐVVC" } },
                },
              },
              importTransport: false &&
                userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                  attrs: {
                    color: "default",
                    dark: false,
                  },
                  content: {
                    icon: "mdi-upload",
                  },
                  target: {
                    tooltip: {
                      content: { text: "Cập nhật trạng thái pick up" },
                    },
                    dialog: {
                      handlers: {
                        initialize() {
                          this.options.content.buttons.save.states.enabled = new DataContainer(false);
                        },
                      },
                      content: {
                        toolbar: {
                          title: "Cập nhật trạng thái pick up",
                        },
                        content: {
                          type: "XForm",
                          makeAttrs(attrs, dialog) {
                            attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                            attrs.xContext = dialog;
                            attrs.xData = new DataContainer({});
                            return attrs;
                          },
                          attrs: {
                            xOptions: {
                              content: {
                                sections: {
                                  default: {
                                    fields: {
                                      file: {
                                        type: "file",
                                        attrs: {
                                          label: "File",
                                          required: true,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        buttons: {
                          save: {
                            content: {
                              text: "Tải lên",
                            },
                            states: {},
                            on: {
                              async xClick({ self }) {
                                const dialog = self.context();
                                const btn = dialog.context();
                                const { item, dataTable } = btn.context();
                                const form = dialog.contentContainer.value;
                                const data = form.getData();
                                self.loading.value = true;
                                const result = await dataTable.execute(
                                  async () => {
                                    return await coreApiWorkerClient.call(
                                      "warehouseExports",
                                      "importTransport",
                                      {
                                        id: item._id,
                                      },
                                      data
                                    );
                                  },
                                  undefined,
                                  {
                                    disableLoading: true,
                                    willRefresh: true,
                                  }
                                );
                                self.loading.value = false;
                                if (result) {
                                  dialog.hide();
                                }
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              detachNotTransportedOrders: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  icon: "mdi-call-split",
                },
                target: {
                  tooltip: {
                    content: { text: "Tách các đơn chưa vận chuyển" },
                  },
                  confirm: {
                    content: {
                      content: {
                        html: "Xác nhận tách các đơn chưa vận chuyển?",
                      },
                    },
                    on: {
                      async xYes({ self: dialog }) {
                        const btn = dialog.context();
                        const { item, dataTable } = btn.context();
                        const result = await dataTable.execute(
                          async () => {
                            return await coreApiWorkerClient.call("warehouseExports", "detachNotTransportedOrders", {
                              id: item._id,
                            });
                          },
                          undefined,
                          {
                            willRefresh: true,
                          }
                        );
                        if (result) {
                          dialog.hide();
                        }
                      },
                    },
                  },
                },
              },
              sendToJnt: userManager.checkRole(["kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  icon: "mdi-send",
                },
                target: {
                  tooltip: {
                    content: { text: "Gửi J&T" },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Xác nhận gửi các đơn này cho J&T Express",
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    shippingCompanyAccountId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Tài khoản ĐVVC",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call(
                                                "shippingCompanyAccounts",
                                                "findAll",
                                                {
                                                  payload: JSON.stringify({
                                                    limit,
                                                    search,
                                                    filters: [
                                                      {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value,
                                                      },
                                                    ],
                                                  }),
                                                }
                                              );
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Gửi đi",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiWorkerClient.call("warehouseExports", "sendToJnt", {
                                    id: item._id,
                                    shippingCompanyAccountId: data.shippingCompanyAccountId,
                                  });
                                },
                                undefined,
                                {
                                  willRefresh: true,
                                }
                              );
                              if (result) {
                                alert(`Số lượng đơn hàng được gửi thành công: ${result.doneCount}`);
                              } else {
                                alert("Có lỗi xảy ra, vui lòng thử lại");
                              }
                              dialog.hide();
                            },
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  condition(_, item) {
                    return ["kt", "cxk"].includes(item.status);
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            options.orderItems = [];
            if (options.orderBy) {
              options.orderItems.push({
                field: options.orderBy,
                type: options.orderType,
              });
              options.orderBy = undefined;
              options.orderType = undefined;
            }
            options.orderItems.push({
              field: "statusValue",
              type: "asc",
            });
            options.orderItems.push({
              field: "createdTime",
              type: "desc",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiWorkerClient.call("warehouseExports", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.marketId = dataTable.context().$route.params.marketId;
              return await coreApiWorkerClient.call("warehouseExports", "createMany", undefined, item);
            },
            async update(item) {
              return await coreApiWorkerClient.call(
                "warehouseExports",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiWorkerClient.call("warehouseExports", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                },
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  colLength: 6,
                  condition() {
                    return userManager.checkRole(["admin", "kt", "vd", "vdl", "qlk"]);
                  },
                },
              },
              shippingCompanyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đơn vị vận chuyển",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("shippingCompanies", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  colLength: 6,
                  condition() {
                    return userManager.checkRole(["admin", "kt"]);
                  },
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(warehouseExportStatuses),
                },
                ext: {
                  defaultValue: "",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["admin", "kt"]);
                  },
                },
              },
              orderIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Orders",
                  "item-value": "_id",
                  "item-text": "orderId",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("orders", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition() {
                    return userManager.checkRole(["admin", "kt"]);
                  },
                },
              },
            },
            editForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["name", "warehouseId", "shippingCompanyId", "description", "orderIds"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "_id",
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
